<template>
  <div class="home hotel container">
    <Carousel height="720px" theme="banner" />
    <div class="page-intro text-center">
      <div class="page-title">
        <div class="tag">Hotel</div>
        <h1>QFOX LUXUARY</h1>
      </div>
      <div class="page-content">
        <p>
          Situated in Kutchan, 7 km from Niseko, TORIFITO HOTEL&POD NISEKO features accommodation with a restaurant, free private parking and a shared lounge. Among the facilities at this property are a 24-hour front desk and luggage storage space, along with free WiFi throughout the property. The
          property is non-smoking and is set 13 km from Shinsen Marsh.At the ryokan, rooms are equipped with a desk, a flat-screen TV, a shared bathroom, bed linen and towels. All rooms have a safety deposit box. TORIFITO HOTEL&POD NISEKO offers a à la carte or American breakfast.
        </p>
        <ul class="hotel-facility">
          <li><img src="../assets/images/icon-parking.svg" /></li>
          <li><img src="../assets/images/icon-pets.svg" /></li>
          <li><img src="../assets/images/icon-smoking.svg" /></li>
          <li><img src="../assets/images/icon-wifi.svg" /></li>
          <li><img src="../assets/images/icon-lift.svg" /></li>
          <li><img src="../assets/images/icon-washing.svg" /></li>
        </ul>
      </div>
    </div>
    <div class="p20">
      <!-- TODO 搜索 -->
      <div class="activity-list room-list">
        <div class="item">
          <div class="item-pics">
            <Carousel height="396px" />
          </div>
          <div class="item-content">
            <div class="item-head">
              <div class="title">Double Room</div>
            </div>
            <ul class="intro">
              <li class="serve">Capacity: 2 guests</li>
              <li class="area">Floor area: 20m</li>
              <li class="bed">Bed: Queen size x 1</li>
              <li class="bathroom">Bathroom x 1</li>
              <li class="serve">Capacity: 2 guests</li>
              <li class="area">Floor area: 20m</li>
              <li class="bed">Bed: Queen size x 1</li>
              <li class="bathroom">Bathroom x 1</li>
            </ul>
            <div class="item-footer df jcsb">
              <div class="btn btn-theme2" style="width: 280px">Show Detail</div>
              <a href="#" class="btn btn-360">360° VIEW</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-pics">
            <Carousel height="396px" />
          </div>
          <div class="item-content">
            <div class="item-head">
              <div class="title">Double Room</div>
            </div>
            <ul class="intro">
              <li class="serve">Capacity: 2 guests</li>
              <li class="area">Floor area: 20m</li>
              <li class="bed">Bed: Queen size x 1</li>
              <li class="bathroom">Bathroom x 1</li>
              <li class="serve">Capacity: 2 guests</li>
              <li class="area">Floor area: 20m</li>
              <li class="bed">Bed: Queen size x 1</li>
              <li class="bathroom">Bathroom x 1</li>
            </ul>
            <div class="item-footer df jcsb">
              <div class="btn btn-theme2" style="width: 280px">Show Detail</div>
              <a href="#" class="btn btn-disabled">360° VIEW</a>
            </div>
          </div>
        </div>
      </div>
      <div class="pic-list activity">
        <div class="item" :style="`background-image: url('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg')`">
          <el-button type="primary" round>SNOWBOARD LESSON</el-button>
        </div>
        <div class="item">
          <el-button type="primary" round>HIKING GUIDE</el-button>
        </div>
        <div class="item">
          <el-button type="primary" round>DIVING LESSON</el-button>
        </div>
      </div>
      <div class="pic-list product">
        <div class="item">
          <el-button type="primary" round>BEVERAGE</el-button>
        </div>
        <div class="item">
          <el-button type="primary" round>HOTPOT DINNER</el-button>
        </div>
        <div class="item">
          <el-button type="primary" round>SNACK</el-button>
        </div>
      </div>
      <div class="pic-list service">
        <div class="item">
          <el-button type="primary" round>COURIER</el-button>
        </div>
        <div class="item">
          <el-button type="primary" round>AIRPORT PICK-UP</el-button>
        </div>
        <div class="item">
          <el-button type="primary" round>ROOM CLEANING</el-button>
        </div>
      </div>
      <div class="hotel-map">
        <img src="../assets/pic/map.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'

export default {
  name: 'Home',
  components: {
    Carousel,
  },
  data() {
    return {
      form: {
        destination: '',
        date: '',
        guest: 1,
        room: 1,
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleChange(value) {
      console.log(value)
    },
  },
}
</script>
